.research {
  margin: 0rem;
  padding: 0rem;
}
.firstSecResearch {
  display: flex;
}

.researchRightSide {
  margin-left: 35px;
}

.researchFocus {
  font-size: 1rem !important;
}

.researchSideBar {
  /* padding-top: 15px; */
  margin-left: 0px;
  padding-left: 0px;
  margin-right: 0rem;
  padding-right: 0rem;
  border-right: solid var(--darkRed) 3px;
  width: 50px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.intro {
  padding: 1.5rem 1.5rem 0rem 4rem;
  margin-bottom: 0rem;
}

.sec2 {
  padding-top: 3rem;
}

.redHeaderResearch {
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  padding: 1rem 4rem 0rem 1rem;
}


.blackHeaderResearch {
  /* padding-top: 100px; */
  font-size: 15px !important;
  font-weight: 700;
  /* text-transform: uppercase; */
  width: fit-content;
  padding-top: 35px;
  padding-right: 15px;
}
.blackHeaderResearch2 {
  /* padding-top: 100px; */
  font-size: 12px !important;
  font-weight: 700;
  /* font-style: italic; */
color: var(--darkBlueLogo);
  /* text-transform: uppercase; */
  width: fit-content;
  padding-top: 35px;
  padding-right: 15px;
}
.blackHeaderResearchNum {
  font-size: 15px !important;
  font-weight: 700;
  width: fit-content;
  padding-top: 35px;
}
.blackHeaderResearchMain {
  font-size: 15px !important;
  font-weight: 700;
  width: fit-content;
  padding-right: 15px;
}

.redSmHeaderResearch {
  /* padding-top: 100px; */
  font-size: 15px !important;
  font-weight: 700;
  text-transform: uppercase;
  width: fit-content;
  color: var(--darkRed);
  margin-bottom: 0rem !important;
  padding-right: 15px;
}

.researchOverview {
  display: flex;
  flex-direction: row;
  /* margin-left: 0px;
  padding-left: 0px;
  margin-right: 0px;
  padding-right: 0px; */
}

.overviewCont {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
.imgOverview {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  padding-left:7rem;
}

.researchHero{
  padding: 15px ;
  background-color: #f5f5f5;
}

.rightArrow {
  /* margin-top: 200px; */
  /* font-size: 5rem; */
  /* width: fit-content; */
}
.centerArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
}

.thera {
  /* margin-top: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hypothesis {
  /* padding: 2rem 2rem 2rem 2rem; */
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hypothText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hypothText2 {
  padding-left: 35px;
  text-align: justify;
}

.usesRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.surfactantProteins {
  width: 100%;
  max-width: 550px;
}


/* .therapeutics {
  width: 170px;
} */

.motivation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sizing {
  width: 100%;
}

.motivation p {
  font-size: 1rem;
}

.diseasesRow {
  display: flex;
  flex-direction: row;
}

.diseasesCont {
  display: flex;
}

.diseasesList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: solid black 2px;
}

.diseaseStyling {
  width: fit-content;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.diseasesList .diseaseStyling {
  font-size: 0.9rem;
}

.clipboardCont {
  width: 300px;
  /* height: 350px; */
  position: relative;
  display: flex;
}

.clipboard {
  /* width: 305px; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.medical {
  font-size: 400px;
}

.lungs {
  max-width: 650px;
}

.inflammation {
  height: fit-content;
  display: flex;
  justify-content: center;
}

.inflammationText {
  display: flex;
  align-items: center;
}

.inflammationLungs {
  display: flex;
  justify-content: center;
}

.lungs2 {
  display: flex;
  flex-direction: row;
}

.research section {
}

.methods {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
  padding-top: 0rem;
  margin-top: 0rem;
  padding-bottom: 0rem;
  padding-top: 0rem;
}

.projects2 {
  padding-left: 4rem;
  padding-right: 0rem;
  margin-top: 0rem;
  /* padding-bottom: 4rem; */
  padding-top: 0rem;
}


.projects {
  padding-left: 4rem;
  padding-right: 4rem;
  /* padding-bottom: 4rem; */
}
.researchFocus{
  font-size: 18px !important;
  font-weight: 700;
}
.redSmHeaderMethods {
  /* padding-top: 100px; */
  font-size: 15px !important;
  font-weight: 700;
  text-transform: uppercase;
  width: fit-content;
  color: var(--darkRed);
  margin-bottom: 0rem !important;
  margin-left:-1rem;
}

@media screen and (max-width: 992px) {
  .rightArrow {
    display: flex;
  }
  .redHeaderResearch {
    font-size: 20px !important;
    color: var(--darkRed);
    text-transform: uppercase;
    padding: 1rem 4rem 0rem 0rem;
  }
  .overviewCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .researchSideBar {
    /* padding-top: 15px; */
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0rem;
    padding-right: 0rem;
    border-right: solid var(--darkRed) 3px;
    width: 50px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .blackHeaderResearch {
    font-size: 15px !important;
    padding-right: 5px;
    
  }
  .blackHeaderResearch2 {
    font-size: 14.4px !important;
    padding-right: 5px;
  }
  .blackHeaderResearchNum {
    font-size: 13px !important;
    margin-right: -10px;
  }
  .blackHeaderResearchMain {
    font-size: 13px !important;
    padding-right: 5px;
  }
  .redSmHeaderMethods {
    /* padding-top: 100px; */
    font-size: 15px !important;
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    color: var(--darkRed);
    margin-bottom: 0rem !important;
    margin-left:-2rem;
  }
   .redSmHeaderResearch {
    /* padding-top: 100px; */
    font-size: 15px !important;
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    color: var(--darkRed);
    margin-bottom: 0rem !important;
    margin-left:-1rem;
  }
  .researchSideBar {
    /* padding-top: 15px; */
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0rem;
    padding-right: 0rem;
    
  }
  .blackHeaderMethods{
    margin-left: -1.5rem;
   
    font-size: 15px !important;
    font-weight: 700;
  /* text-transform: uppercase; */
    width: fit-content;
    padding-top: 35px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 576px) {
  .videoWrapper {
    justify-content: center;
  }
  .researchCont {
    margin: 0px;
    margin-left: 0px;
  }
  .hypothText2 {
    padding-left: 0px;
  }
  /* .intro {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .methods {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .projects {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  } */
  .projects2 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
  }
  .blackHeaderResearch {
    /* padding-top: 100px; */
    font-size: 15px !important;
    font-weight: 700;
    /* text-transform: uppercase; */
    align-items: left;
    padding-top: 35px;
    
  }
  .redSmHeaderResearch {
    /* padding-top: 100px; */
    font-size: 15px !important;
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    color: var(--darkRed);
    margin-bottom: 0rem !important;
    padding-right: 15px;
  }
  .blackHeaderResearchNum {
    padding-bottom: 0px;
  }
  .blackHeaderResearchMain {
    font-size: 15px !important;
    font-weight: 700;
    width: fit-content;
    padding-right: 15px;
  }
 
  .headerNumMobile{
    display: flex;
    flex-direction: row;
  }
  .methods {
  padding-left: 2rem;
  }
  .projects {
    padding-left: calc(1.5rem + 15px);
    padding-right: 1.5rem;
  }
  .intro {
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-bottom: 0rem;
  }
  .researchRightSide {
    margin-left: 35px;
  }
  .fixToLeft{
    align-self: start;
  }

  .researchFocus{
    margin-bottom: 35px;
    margin-right: 15%;
  }
  .topH{
    padding-left: 2rem !important;
  }
  .researchSideBar {
    /* padding-top: 15px; */
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0rem;
    padding-right: 0rem;
    
  }
}
