.linkButton {
  /* border:solid white 2px; */
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 900;
  border-radius: 0px;
  /* border-radius: 5px; */
}

.dark {
  background-color: black;
  color: white;
  border: solid black 1px;
}

.light {
  border: solid  1px;
  position: relative;
  background-image: url('/public/images/HomePics/bst.png'); /* Directly apply the background */
  background-size: cover; /* Make sure the image covers the entire element */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  border-radius: 90px;
}

/* Remove the unnecessary pseudo-element or modify it if you still need it */
.light::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
 
}
.dark:hover {
  background-color: white !important;
  color: black;
  border: solid white 1px !important;
}

.light:hover {
  color: black !important;
  background-color: white !important;
  border: solid white 1px !important;
  
}
.light:focus{
  border: solid white 1px !important;
  background-color: white !important;
}


