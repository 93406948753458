.news {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 4rem;
}

.newsRow {
  border-bottom: solid rgba(151, 151, 151, 0.25) 4px;
  /* padding-top: 2rem;
  padding-bottom: 2rem; */
  /* margin: 16px; */
  /* height: 550px; */
  padding-top: 35px;
  padding-bottom: 35px;
  margin-left: 35px;
  margin-right: 35px;
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  /* border-radius: 5px; */
}

.newsRow0 {
  /* border-top: solid black 4px; */
  border-bottom: solid rgba(151, 151, 151, 0.25) 4px;
  /* padding-top: 6rem;
  padding-bottom: 2rem; */
  /* margin: 16px; */
  /* height: 550px; */
  padding-top: 35px;
  padding-bottom: 35px;
  margin-left: 35px;
  margin-right: 35px;
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  /* border-radius: 5px; */
}


.nsfCareer{
  width: 400px;

  /* height: 300px; */
}

.nsfCareerCont{
  width: 400px;
  height: 350px;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgba(0, 0, 0, .1);
  background: var(--darkBlue); */
}

.nsfCareer2{
  /* width: 400px; */
  /* height: 350px; */
  object-fit: cover;

}

.newsDate {
  padding-top: 35px;
  font-size: 18px !important;
  font-weight: 700;
}

.newsHeader{
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  padding: 2rem 4rem .5rem 2rem;
}

.newsTitle {
  font-size: 21px !important;
  line-height: 26px !important;
  color: var(--darkRed);
  /* color: var(--grayBlue); */
}

.featuredNewsRow{
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newsBG{
  background-color: #f5f5f5;
}
.featuredNewsTitle{
  padding-top: 35px;
  font-size: 34px !important;
  line-height: 1.07 !important;
  color: var(--darkRed);
  text-transform: uppercase;
  background-color: #f5f5f5;
}

.featuredNews{
  margin-top: -30px;
  font-size: 16px !important;
  font-style: italic;
  font-weight: 500;
}
.featuredNewsDate {
  font-size: 18px !important;
  font-weight: 700;
}

.imgCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 350px;
  padding: 16px 0px 16px 16px;
  align-self: center;
  float: right;
  width: fit-content;
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  /* border-radius: 5px; */
}

.newsLink {
  color: black;
  text-decoration: none;
}

.summary {
  font-size: 18px !important;
}

.newsImg {
  /* padding-top: 16px;
  padding-bottom: 16px; */
  width: 400px;
  height: 350px;
  /* width: 300px;
  height: 300px; */
  object-fit: cover;
  /* flex-shrink: 2; */

  /* border-radius: 5px; */
}


.imgOverflow {
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .newsWrapper {
    justify-content: center;
  }
  .newsCont {
    margin: 0px;
    padding: 0px;
  }
  .news {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .newsLink {
    width: 100%;
  }
  .newsRow {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 0px;
  }
  .newsRow0 {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 0px;
  }
  .imgCont {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .newsCol {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

@media screen and (max-width: 900px) {
  .imgCont {
    float: none;
  }
  .newsLink {
    /* width: 350px; */
  }
  .newsCol {
    display: flex;
    justify-content: center;
  }
  .newsRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
  .newsRow0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
}
