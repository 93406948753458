.heroWrapper {
  width: 100%;
  /* height: 650px; */
  height: 280px;
  position: relative;
  display: flex;
}
.heroWrapperPub {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
}

.heroCont {
  width: 375px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-left: 4rem;
  background-color: white;
  /* border-radius: 10px; */
  padding: 7px;
}

.heroCard {
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
  padding-top: 15px;
  color: black !important;
}

.bgPic {
  position: absolute;
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  /* width: calc(100% - 1rem);
  height: calc(100% - 0.5rem); */
  /* margin: 0rem 0.5rem 0rem 0.5rem; */
  /* padding: 1rem; */
  z-index: -2;
  /* width: calc(100% -45px); */
  /* height: 100%; */
  object-fit: cover;
  /* border-radius: 10px; */

}

.hero3D {
  box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.featuredHero{
  /* opacity: .3; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background: linear-gradient(to right, transparent, rgba(255, 255, 255, 1) ); */

}
.hrHeroFeat{
  margin-top: -3px;
  border-top: 3px solid var(--grayBlue);
  opacity: 1;
  background-color: white;
}

@media screen and (max-width: 576px) {
  .heroWrapper {
    justify-content: center;
    height: 150px;
  }
  .heroCont {
    margin: 0px;
    padding: 25px;
  }
  .hrHeroFeat{
    margin-top: -10px;
    margin-bottom: 7px;
  }
}

/* .heroWrapper {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
}

.heroCont {
  width: 375px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-left: 4rem;
  background-color: white;
  border-radius: 10px;
  padding: 7px;
}

.heroCard {
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
  padding-top: 15px;
  color: black !important;
}

.bgPic {
  position: absolute;
  box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc;
  width: calc(100% - 1rem);
  height: calc(100% - 0.5rem);
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 1rem;
  z-index: -2;
  width: calc(100% -45px);
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.hero3D {
  box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .heroWrapper {
    justify-content: center;
  }
  .heroCont {
    margin: 0px;
    padding: 25px;
  }
} */
