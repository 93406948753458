.nuHeader {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 20px;
}

/* Global Header */
.headerNU {
  height: 48px;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
}
.NLogo{
  width: 120px;
}

.university-title {
  display: flex;
}

.university-name {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.university-name:hover {
  color: rgb(246, 6, 6) !important;
}

.icon-n {
  /* position: absolute; */
  /* top: 0; */
  width: 48px;
  height: 48px;
  background-color: rgba(212, 27, 44, 1) !important;

  display: flex;
  justify-content: center;
}

.icon-n:hover {
  background-color: rgb(183, 17, 31) !important;
}

.nu-global .w-7 {
  width: 28px !important;
}

.nu-global .h-7 {
  width: 28px !important;
}
