/* margins should be 4 rem on all sides of the page
on mobile- it should be 4 rem top and bottom and 1.5 rem left and right
margin bottom should be 4rem at the very bottom of all pages

h3s all will have margin bottom 2rem as indicated in global css styling below
 */


 /* Minkara COMBINE Lab Hex Codes and Fonts 

 
Red: ED1C24 

Light blue: 5495C4 

Dark blue: 335975 

Grey: A7A9AC 


The font for the logo is Work Sans, specifically Extra Light and Semi Bold. The italic font at the bottom is PT Serif.  */

:root {
  --electricBlue: #5495c4;
  /* --electricBlue: rgb(8, 146, 208); */
  --electricBluergb: 8, 146, 208;
  --darkBlue: #16242f;
  --navyBlue: #1d2951;
  --grayBlue: #335975;
  /* --red: rgb(182, 6, 2); */
  /* --redrgb: 182, 6, 2; */
  --lightRed: rgb(136, 8, 8);
  --lightRedrgba: 136, 8, 8;
  --lightBlue: #eaf8ff;
  --lightBluergb: 234, 248, 255;
  --darkRed: #c10f2d; /*logo red*/
  --red: rgb(212, 27, 44);
  --redrgb: 212, 27, 44;
  --softBluergb: 83, 149, 196;
  --logoGray: #A7A9AC;
  --logoGrayrgb: 167, 169, 172;
  --logoLightBlue: #5495C4;
  --logoLightBluergb: 84, 149, 196;
  --buttonLightHoverrgb: 220, 235, 241;
  --darkBlueLogo: #335975;
}

.app {
  * {
    font-family: "system-ui", "-apple-system", "Segoe UI", "Roboto",
      "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    /* font-family: "Lato, Helvetica, Arial, Lucida, sans-serif"; */
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }
 
  .combine {
    color: var(--red);
  }
  a {
    text-decoration: none;
  }
  h2 {
    font-size: 3rem;
    font-weight: 700;
 
    /* text-shadow: 0.8px 0.8px 0.8px #c7c4c4, -0.8px -0.8px 0.8px #fff; */
  }

  h3 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
    /* text-shadow: 0.8px 0.8px 0.8px #c7c4c4, -0.8px -0.8px 0.8px #fff; */
    /* color: var(--navyBlue); */
  }

  h4 {
    font-size: 2.2rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  p {
    /* font-size: 1.3rem; */
    /* line-height: 26px; */
    /* text-shadow: .4px .4px .4px #c7c4c4, -.4px -.4px .4px #fff; */
  }

  .asterisks {
    font-size: 0.8rem;
  }
  .bottomPad {
    padding-bottom: 5rem;
  }
}

