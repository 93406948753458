.linkedIn {
  /* display: flex;
  justify-content: center; */
  /* border: solid red 1px; */
  /* width: 45%; */
  /* height: 500px; */
}

.linkedIn h3 {
  font-size: 1.2rem;
}

.liIcon {
  color: #0077b5;
  font-size: 3rem;
  margin-left: 35px;
}
