.groupContainer {
  padding: 2rem 4rem 4rem 4rem;
}

.groupSec {
  padding-top: 2rem;
}

.labMembersTop{
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  /* padding-top: 2rem; */
  padding: 1rem 4rem 1rem 1rem;
}

.labMembers {
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  padding-top: 2rem;
  /* padding-bottom: 1.5rem; */
 
}

.heroGroup{
  display: flex;
  align-items: center;
  justify-content: center;

  

}
.header_grp{
  display: block;
  width: 100%;
}
.groupHero{
  padding: 2rem 4rem 4rem 4rem;
  background-color: #f5f5f5;
}
.groupPicHero{
  
  width: 100%;
  height: 50%;

}

.alumniBG{
  background-color: #f5f5f5;
  padding: 0rem 4rem 4rem 4rem;
  margin: 0rem;
}
@media screen and (max-width: 992px) {
  .groupContainer {
    padding-top:0.5rem ;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .groupPicHero{
  margin-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 200px;
  width:700px;

  }
  .labMembersTop {
    font-size: 20px !important;
    color: var(--darkRed);
    text-transform: uppercase;
    /* padding-top: 2rem; */
    padding: 1rem 2rem 1rem 1rem;
  }
  .groupHero{
    padding: 5px;
    background-color: #f5f5f5;
  }
 
}