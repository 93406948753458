.join {
  display: flex;
  flex-direction: row;
  height: 45px;
  width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 5px;
  border-radius: 40px;
  color: white;
  /* background-color: var(--red); */
  background-color: rgb(212, 27, 44);
  /* border: solid white 2px; */
  border: solid rgba(255, 255, 255, 0.8) 1px;
  /* border: none; */
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  padding-top: 7px;


  /* box-shadow: -5px -5px 9px rgba(var(--redrgb), 0.45),
    5px 5px 9px rgba(var(--redrgb), 0.3); */
  /* padding: 15px; */
  /* box-shadow: inset -5px -5px 9px rgba(94, 104, 121, 0.3),
    inset 5px 5px 9px rgba(94, 104, 121, 0.3); */
}

.join:hover {
  /* background-color: var(--darkRed); */
  background-color: rgb(183, 17, 31);
  border: solid rgba(255, 255, 255, 0.8) 1px;
}

@media screen and (max-width: 1030px) {
  /* .join {
    width: 100%;
    margin-left: 0px;
    border-radius: 15px;
  } */
}
