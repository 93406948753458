.contactDiv {
  padding: 4rem 4rem 4rem 4rem;
}

.contactRow1 {
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  gap: 35px;
}
.contactUs{
  font-size: 20px !important;
  /* color: var(--darkRed); */
  text-transform: uppercase;
}
.contactCol1 {
  max-width: 600px;
}

.bottomContact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}

.contactForm {
  /* padding: 0 3rem; */
  /* max-width: 1200px; */
  margin: auto;

  /* font-size: 40px; */
  /* border-radius: 4px; */
}

.contactTopHeader{
  font-size: 20px !important;
  color: var(--darkRed);
  text-transform: uppercase;
  /* padding-top: 2rem; */
  padding: 1rem 4rem .5rem 1rem;
}

.contactHero{
  padding: 15px;
  background-color: #f5f5f5;
}

.contactHeroImg{
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.contactHeaders {
  font-size: 1rem !important;
  color: var(--darkRed);
  margin-bottom: 5px !important;
  /* font-weight: 700; */
  /* border-bottom: solid var(--red) 2px;
  width: fit-content; */
}

.contactInfo {
  display: flex;
  flex-direction: row;
  /* width: 300px; */
  width: 100%;
  height: fit-content;
  gap: 35px 55px;
  flex-wrap: wrap;
  padding-top: 15px;
 
}

.contactInfo1 {
  border-right: solid var(--darkRed) 3px;
  max-width: 600px;
}

.contactInfo p {
  margin: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px;
}

.email {
  flex-wrap: wrap;
  margin: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px;
  gap: 15px;
}

.formLabel {
  font-size: 15px;
}

.officeIcon {
  width: 20px;
  /* border: solid red 1px; */
}

.box3D {
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  outline: solid black 1px;
  border-radius: 2px;
}
.box3DCont {
  /* border: solid #e0e0e0 0.05px; */
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset 0px 0px 12px #fcfcfc; */
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35) inset; */

  padding: 35px;
  /* border-radius: 10px; */
  padding-bottom: 50px;
}

.centerIcon {
  align-self: center;
}

.form-control {
  border: none;
}

.iconContact {
  display: flex;
  gap: 15px;
}
.contactIcon {
  /* color: rgb(var(--softBluergb)); */
}

.department {
  flex-wrap: wrap;
}

.contactInfo a {
  color: black;
}

.contactSoc {
  display: flex;
  gap: 15px;
}

.contactInfo a {
  font-size: 1rem !important;
  /* color: var(--red); */
}

.contactInfo p {
  font-size: 1rem !important;
  /* color: var(--red); */
}

.halfName {
  width: 100%;
}

.fullName {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: fit-content;
}

.formLabel:after {
  content: "*";
  padding-left: 5px;
  color: var(--darkRed);
}

.nameCont {
  width: 100%;
  height: 60px;
}

.nameCont > p {
  font-size: 0.8rem;
}

.contactSec {
  width: 230px;
}

@media screen and (max-width: 576px) {
  .contactWrapper {
    justify-content: center;
  }
  .contactCont {
    margin: 0px;
    padding: 10px;
  }
  .contactRow1 {
    margin: 0px;
    justify-content: start;
  }

  .contactDiv {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .contactInfo {
    margin: 0px;
    /* padding-top: 0rem; */
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .contactForm {
    padding: 0rem;
  }
  .contactDiv > h3 {
    /* margin: 0px !important; */
    /* padding: 0px; */
  }
  .contactCol1 {
    margin: 0px !important;
    padding: 0px;
  }
}

@media screen and (max-width: 375px) {
  .contactCont {
    width: 300px;
    height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .contactInfo {
    flex-direction: row;
    width: auto;
    /* justify-content: space-around; */
    gap: 0px, 15px;
    padding-top: 15px;
  }
  .contactInfo1 {
    border-right: none;
  }
}
