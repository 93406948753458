.programRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 3rem;
  
  background-color: #ffffff;
  /* background-color: #6E667B; */
  /* background-color: #335974; */
  /* background-color: #07406b; */
  gap: 35px 35px;
  padding-left: 35px;
  padding-right: 35px;
}
.programRow1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  
  background-color: #ffffff;
  /* background-color: #6E667B; */
  /* background-color: #335974; */
  /* background-color: #07406b; */
  gap: 25px 35px;
  padding-left: 35px;
  padding-right: 35px;
}
.programsCont3D {
  /* box-shadow: inset 5px 5px 12px #e0e0e0, inset -5px -5px 12px #fcfcfc; */
  padding-top: 1rem;
  background-color: #ffffff;
}

/* .bottomPrograms {
  height: 170px;
  width: 100vw;
} */

.programCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-right: -15px;
}
.programCont2 {
  display: block;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: fit-content;
  margin-right: -15px;
}

.number {
  padding-right: 5px;
  /* font-weight: bold; */
}

/* .bgLens{
  position: absolute;
  width: 280px;
  height: 280px;
  object-fit: cover;
  z-index: 1;
} */

.programHome {
  align-self: center;
  color: black;
  /* background-color: rgb(245, 245, 245, .85); */
  width: fit-content;
  padding: 7px;
  /* font-weight: 900; */
}

.programTitle{
 
}

/* .imageHexagon {
  position: relative;
} */

.circle {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: solid black 6px;
}

.mag{
  position: relative;

}
.handle {
  position: absolute;
  width: 20px; 
  height: 70px; 
  background-color: black; 
top: 210px;
left: 12px;
  transform: rotate(45deg); 
  /* transform-origin: top left;  */
}

.projectsHeader {
  display: flex;
  justify-content: center;
}

.learnProject {
  font-size: 18px!important;
  text-align: left;
  font-style: italic;
  color: black;


}
.obj{
  font-size:18px;
  text-align: left;
}
.img_des {
  font-size: 30px!important;
  text-align: center;
  color: black;
  margin-top: 5%;

}
.img_des1 {
  text-align: left;
  font-size:18px;
  color: black;
}
.bigimage {
  width: 2250px;  /* Adjust the size as needed */
  height: 120%;  /* Maintain aspect ratio */
  border: 2px solid #000;  /* Add border here if preferred */
  padding-bottom:10px ;
}

.ImageProgram {
  /* width: 240px; */
  height: 240px;
  object-fit: cover;
  /* position: absolute;  */
  /* backdrop-filter: blur(10px); */
  /* position: absolute;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -2;
  opacity: .8; */
}

.fix{
  padding-bottom: 35px;
  
}
.obj-desc{
  font-size: 25px;
  margin-left: 30px; 
}
.desc{
  margin-left: 50px; 
  background-color: #ffffff;
  height:100%;
}
.desc-sec{
  background-color: #ffffff;
  
}
/* .hoverZoom{
  transition: transform .2s;
}

.hoverZoom:hover {
  transform: scale(1.5); 
} */

.linkImage {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  /* margin-bottom: -170px; */
}

/* HexagonImage.css */

.hexagon {
  width: 250px; /* Adjust the width of the hexagon */
  height: 250px; /* This value is calculated for a regular hexagon */
  position: relative;
  margin: 57.74px 0;
  /* display: flex;
  flex-direction: row; */
  align-self: center;
  /* background-color: #f1e5d6; */
  background-color: var(--grayBlue);
  /* background-color: white; */
  /* background-color: white; */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

/* .hexagon div {
  background-color: #045a86;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  height: 240px;
  position: absolute;
  object-fit: cover;
  top: 5px; 
  left: 5px; */

  /* clip-path: polygon(
    50% 0%,
    85.36% 14.64%,
    100% 50%,
    85.36% 85.36%,
    50% 100%,
    14.64% 85.36%,
    0% 50%,
    14.64% 14.64%
  ); */

  /* -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%); */

  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */

  /* border: solid white 10px; */
  /* box-shadow: 5 5 10px rgba(123, 4, 124, 0.5); */
/* } */

@media screen and (max-width:1420px) {
  .linkImage {
    /* margin-bottom: -80px; */
  }
  .mag {
    display: flex;                 /* Use Flexbox for layout */
    flex-wrap: nowrap;             /* Prevent elements from wrapping to the next line */
    justify-content: space-between; /* Adjust the space between items */
    align-items: center;           /* Align items vertically in the center */
    overflow: hidden;              /* Prevent any overflow */
  }
  .ImageProgram {
    max-width: 150px;   
    max-height: 150px;         /* Reduce the size for smaller screens */
  }
}  